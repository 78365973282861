<template>
  <div style="background: black">
    <html>
      <body
        class="enable-animations layout-column"
        shortcuts=""
        style="
          background: black;
          height: 100vh;
          min-height: 100%;
          margin: 0;
          padding: 0;
          font-family: Roboto, sans-serif;
          color: #2f3234;
          overflow: hidden !important;
        "
      >
        <div class="space">
          <div id="stars" class="stars"></div>
          <div id="stars2" class="stars2"></div>
          <div id="stars3" class="stars3"></div>
          <div id="solar-syst" class="solar-syst">
            <div class="sun"></div>
            <div class="mercury"></div>
            <div class="venus"></div>
            <div class="earth"></div>
            <div class="mars"></div>
            <div class="jupiter"></div>
            <div class="saturn"></div>
            <div class="uranus"></div>
            <div class="neptune"></div>
            <div class="pluto"></div>
            <div class="asteroids-belt"></div>
          </div>
        </div>
      </body>
    </html>
    <div style="height: 100vh; width: 100vw; position: absolute; top: 0">
      <el-form
        :model="ruleForm2"
        :rules="rules2"
        status-icon
        ref="ruleForm2"
        label-position="left"
        label-width="0px"
        class="demo-ruleForm login-page"
      >
        <h3 class="title">系统登录</h3>
        <el-form-item prop="username">
          <el-input
            type="text"
            v-model="ruleForm2.mobile"
            auto-complete="off"
            placeholder="用户名"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            v-model="ruleForm2.pwd"
            auto-complete="off"
            placeholder="密码"
          ></el-input>
        </el-form-item>
        <el-checkbox v-model="checked" class="rememberme">记住密码</el-checkbox>
        <el-form-item style="width: 100%">
          <el-button
            type="primary"
            style="width: 100%"
            @click="handleSubmit"
            :loading="logining"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
  <!--      -->
</template>

<script>
import { login } from "@/utils/masterdata";
export default {
  data() {
    return {
      logining: false,
      ruleForm2: {
        username: "admin",
        password: "123456",
        mobile: "13861743221",
        pwd: "Dxx123456",
      },

      checked: false,
    };
  },
  methods: {
    handleSubmit() {
      // this.$message.success("登录成功");
      // localStorage.setItem("bigdatatoken", "xctvybuhinjmkljhbgvfc");
      // this.$router.push({
      //   path: "/index",
      // });
      // debugger;
      login(this.ruleForm2).then((e) => {
        console.log(e.body.token);
        localStorage.setItem("bigdatatoken", e.body.token);
        this.$router.push({ path: "/" });
      });
      // this.$refs.ruleForm2.validate((valid) => {
      //   if (valid) {
      //     this.logining = true;
      //     if (
      //       this.ruleForm2.username === "admin" &&
      //       this.ruleForm2.password === "123456"
      //     ) {
      //       this.logining = false;
      //       sessionStorage.setItem("user", this.ruleForm2.username);
      //       this.$router.push({ path: "/" });
      //     } else {
      //       this.logining = false;
      //     }
      //   } else {
      //     console.log("error submit!");
      //     return false;
      //   }
      // });
    },
  },
};
</script>

<style scoped>
.login-container {
  width: 100%;
  height: 100%;
}

.login-page {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin: 350px auto;
  width: 350px;
  padding: 35px 35px 15px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
  position: relative;
  z-index: 1999999999;
}

label.el-checkbox.rememberme {
  margin: 0px 0px 15px;
  text-align: left;
}
</style>
<style lang="less" scoped>
html {
  background-image: url(https://img.cdn.xingningjt.com/uploads//1644411614/1123.jpg);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top center;
}

.common-layout {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  box-sizing: border-box;
  //background: url(../../assets/img/backgroundimage.png) #b8e5f8 no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  overflow: auto;

  .login {
    width: 400px;
    height: 488px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -200px;
    margin-top: -280px;

    img {
      width: 300px;
      margin: 0 50px 30px 50px;
    }
  }

  .login_content {
    border-radius: 10px 10px 0 0;
    background-color: #ffffff;
    padding: 40px 50px;
    box-shadow: 0 7px 15px #504f4fa1;
    text-align: center;

    img {
      width: 280px;
      margin: -20px 0px 26px 0px;
    }

    p {
      color: #121212;
      font-weight: 500;
      font-size: 24px;
      margin-bottom: 16px;
    }

    .wx {
      color: #888;
      font-size: 14px;
      margin: 20px 0 -10px;
    }
  }

  .footer {
    background: #f6f6f6;
    text-align: center;
    border-radius: 0 0 10px 10px;
    padding: 15px;
  }
}

.wxlogin_border {
  width: 300px;
  height: 200px;
}
</style>
<style scoped src="./solar.css">
</style>
<style scoped src="./stars.css">
</style>
